















































































































































































































































































































.el-radio-group {
  display: flex;
  flex-direction: column;
  .el-radio {
    padding: 10px;
  }
}
